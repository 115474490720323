import request from '@/utils/request'

//下单
export function placeOrder(params) {
  return request({
    url: '/frontend/bigdata/wechat-pay/place-order',
    method: 'post',
    params
  })
	
}

//支付状态查询
export function paystatus(params) {
  return request({
    url: '/frontend/bigdata/wechat-pay/check-pay',
    method: 'post',
    params
  })
	
}


//下载文件 图片
export function downloadfile(params) {
  return request({
    url: '/frontend/bigdata/file-download/pay-batch',
    method: 'post',
    params,
    loading:true
  },)
}




//下载Excel
export function downloadExcel(params) {
  return request({
    url: '/frontend/bigdata/excel-download/down',
    method: 'post',
    params
  })
}


//大数据需要下载文件的列表
export function fileDownList(params) {
  return request({
    url: '/frontend/bigdata/landdata/file-download-list',
    method: 'post',
    params
  })
}


export function confirmapi(params) {
  return request({
    url: '/frontend/bigdata/order/confirm',
    method: 'post',
    params
  })
}







