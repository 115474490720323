<template>
	<div>
		<div class="hangpaidetail">
			<div class="titlebox">
				<div :class="['typebtn', 'typebtn' + data.usecate]">{{ data.usecatename }}</div>
				<div class="title">{{ data.ssq }}-{{ data.location }}</div>
			</div>
			<div class="content">
				<div class="img">
					<!-- <img :src="data.file_url" alt="" /> -->
					<img :src="data.file_url" alt="" />
				</div>
				<div class="imginfo">
					<div class="basicinfo">
						<div class="infoitem">
							<div class="type">
								<div class="typekey">授权范围：</div>
								<div class="typevalue">版权说明</div>
							</div>
							<div class="type">
								<div class="typekey">授权所有：</div>
								<div class="typevalue">土拍网@</div>
							</div>
							<div class="type">
								<div class="typekey">授权方式：</div>
								<div class="typevalue">版权说明</div>
							</div>
							<div class="type">
								<div class="typekey">上传时间：</div>
								<div class="typevalue">{{ data.date }}</div>
							</div>
						</div>
						<div class="infoitem">
							<div class="type">
								<div class="typekey">图片质量：</div>
								<div class="typevalue">高清无水印</div>
							</div>
							<div class="type">
								<div class="typekey">分&nbsp;辨&nbsp;率&nbsp;：</div>
								<div class="typevalue"><span>{{data.file_info.width}}</span>*<span>{{data.file_info.height}}</span> </div>

							</div>
							<div class="type">
								<div class="typekey">图片格式：</div>
								<div class="typevalue">{{ data.file_info.format}}</div>
							</div>
							<div class="type">
								<div class="typekey">图片大小：</div>
								<div class="typevalue">{{ data.fsize }}</div>
							</div>
						</div>
					</div>
					<!-- <div class="downbtn" @click="showVipBox">
						SVIP会员免费下载
					</div>  -->

					<download-button class="downbtn" :isvip="isvip" @closed="closed" :isrun="isrun" :params="params"
						text="SVIP会员免费下载"></download-button>

					<div class="vipbuy" @click="showMemberBox">
						<div class="viptext">普通会员单独购买</div>
						<div class="money">¥{{ data.price }}</div>
					</div>

					<div class="weituo">
						<img class="qrcode" :src="data.hang_contact_url" alt="">
						<div class="contact">
							<div class="top">委托拍摄 扫码联系</div>
							<div class="phonenumber">{{ data.hang_contact_phone_number }}</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { hangpaiDetail } from '@/api/website/hangpai.js'

import DownloadButton from "@/components/down/DownloadButton.vue"
export default {
	name: 'PcWebsiteHangpai',

	data() {
		return {
			data: {
				usecate: '',
				usecatename: '',
				ssq: '',
				location: '',
			},
			isvip:true,
			isrun: false,
			params: {
				goods_id: 9,
				condition: {
					file_ids: 0
				}
			},
			dialogVisible: false,
			dialogMemberVisible: false

		}
	},



	components: {
		DownloadButton
	},

	mounted() {

	

		this.init()
	},

	methods: {

		closed() {
			this.isvip = true
			this.isrun = false
		},


		//显示box弹框
		showVipBox() {
			this.dialogVisible = true
		},

		showMemberBox() {
			this.isvip = false
			this.isrun = true

			// this.dialogMemberVisible = true
		},



		init() {

			hangpaiDetail({ id: this.$route.params.id }).then(res => {
				this.data = res.data
				this.params.condition.file_ids = this.data.file_ids
			})
		}
	},

};
</script>

<style lang="scss" scoped>
.imginfo {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 310px;
	height: 456px;
	margin-right: 20px;

	.basicinfo {
		width: 310px;
		height: 132px;
		background: #F0F3F8;
		display: flex;
		justify-content: space-around;
		align-items: center;

		padding: 5px;
		box-sizing: border-box;

		.infoitem {
			width: 130px;
			height: 112px;
			font-size: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #A0A0A0;
			line-height: 28px;
			display: flex;
			flex-wrap: wrap;

			.type {
				width: 134px;
				display: flex;

				.typekey {
					width: 60px;
					text-align: justify;

				}

				.typevalue {
					width: 72px;
					white-space: nowrap;
					overflow: hidden;
					text-align: left;
					color: #000;
				}
			}

		}

	}

	.downbtn {
		cursor: pointer;
		width: 310px;
		height: 48px;
		background: #00A3E4;
		line-height: 48px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}

	.vipbuy {
		width: 310px;
		height: 92px;
		background: #FFFFFF;
		border: 2px solid #E77817;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		.viptext {
			width: 144px;
			height: 25px;
			font-size: 18px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #E77817;
			line-height: 25px;
		}

		.money {
			height: 33px;
			font-size: 24px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #E77817;
			line-height: 33px;
		}
	}

	.weituo {
		padding: 5px;
		box-sizing: border-box;
		width: 310px;
		height: 118px;
		background: #E77817;
		display: flex;
		justify-content: space-around;
		align-items: center;

		.qrcode {
			width: 100px;
			height: 100px;
			background: #FFFFFF;
		}

		.contact {
			display: flex;
			flex-direction: column;
			justify-content: space-around;

			.top {
				height: 30px;
				font-size: 22px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #FFFFFF;
				line-height: 30px;
			}

			.phonenumber {
				margin-top: 10px;
				height: 30px;
				font-size: 24px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #FFFFFF;
				line-height: 30px;
			}

		}

	}
}

.hangpaidetail {

	width: 1200px;
	height: 540px;
	background: #FFFFFF;

	.titlebox {
		display: flex;
		height: 65px;
		justify-content: flex-start;
		align-items: center;

		.title {
			height: 25px;
			font-size: 18px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
			line-height: 25px;
			margin-left: 10px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}



		.typebtn {
			margin-left: 20px;
			width: 36px;
			height: 20px;
			line-height: 20px;
			border-radius: 2px;
		}

		.typebtn1 {
			background: #FFFF00;
		}

		.typebtn2 {
			background: #FCBB81;
		}

		.typebtn3 {
			color: #fff;
			background: #E40000;
		}

		.typebtn4 {
			background: #E74FD6;
		}
	}

}

.content {
	display: flex;
	justify-content: space-between;

	.img {
		margin-left: 20px;
		width: 830px;
		height: 456px;

		img {
			width: 830px;
			height: 456px;
		}
	}


}
</style>